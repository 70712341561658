
   import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllClients: { args: ["payload"], meta: { async: true } },
    getClientWithId: { args: ["payload"], meta: { async: true } },
    createClient: { args: ["payload"], meta: { async: true } },
    updateClient: { args: ["payload"], meta: { async: true } },
    deleteClient: { args: ["payload"], meta: { async: true } },
    register: { args: ["payload"], meta: { async: true } },
    setRegister: { args: ["payload"], meta: { async: false } },
    getClientInfo: { args: ["payload"], meta: { async: true } },
///
    saveCountry: { args: ["payload"], meta: { async: false } },
    saveUserPhone: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },

  },
  {
    prefix: "@app/Client/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    