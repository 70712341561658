import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";
import { push ,goBack} from "connected-react-router";

const apiNamespace = "service";

const checkParentProviderLogic = logic(apiNamespace, {
  actionName: ActionTypes.CHECK_PARENT_PROVIDER,
});

const getServiceOptionVouchersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_OPTION_VOUCHERS,
});

const getServicesIntegrationLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICES_INTEGRATION,
});

const getServiceChildrenLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_CHILDREN,
});
const getServiceOptionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_OPTIONS,
});
const getAllServicesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_SERVICES,
});
const getServiceProvidersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_PROVIDERS,
});
const getServiceProviderWithIdLogic=logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_PROVIDER_WITH_ID,
});

const getTypesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TYPES,
});
const getServiceTypesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_TYPES,
});
const getAvailableServicesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_AVAILABLE_SERVICES,
});
const validateVoucherLogic = logic(apiNamespace, {
  actionName: ActionTypes.VALIDATE_VOUCHER,
});
const submitRedeemVoucherLogic = logic(apiNamespace, {
  actionName: ActionTypes.SUBMIT_REDEEM_VOUCHER,
});
const redeemLogic = logic(apiNamespace, {
  actionName: ActionTypes.REDEEM,
  showErrorMessage: true,
  errorMessage: <Trans id="invalid_to_redeem" />,
  showSuccessMessage: true,
  successMessage: <Trans id="successful_congrats" />,
});

const getServiceOptionWithLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_OPTION_WITH_ID,
});
const getServiceWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_WITH_ID,
});
const getServiceTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICE_TRANSACTIONS,
});
const createServiceLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SERVICE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/service/list"));
  }
});
const createChildServiceLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CHILD_SERVICE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(goBack());
  }
});
const createServiceTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SERVICE_TYPE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
 
});
const createServiceProviderLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SERVICE_PROVIDER,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(goBack());
  }
 
});
const createVouchersLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_VOUCHERS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(goBack());
  }
});

const createServiceOptionLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SERVICE_OPTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(goBack());
  }
});
const updateServiceLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_SERVICE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/service/list"));
}
});
const updateChildServiceLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_CHILD_SERVICE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(goBack());
}
});
const updateServiceProviderLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_SERVICE_PROVIDER,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(goBack());
  }

});
const updateServiceTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_SERVICE_TYPE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const updateServiceOptionLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_SERVICE_OPTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(goBack());
  }
});
const deleteServiceLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SERVICE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});
const deleteServiceProviderLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SERVICE_PROVIDER,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});
const deleteServiceTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SERVICE_TYPE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});
const deleteServiceOptionLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SERVICE_OPTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getServiceChildrenLogic,
  getServicesIntegrationLogic,
  getServiceOptionsLogic,
  getServiceOptionVouchersLogic,
  checkParentProviderLogic,
  getAllServicesLogic,
  getServiceProvidersLogic,
  validateVoucherLogic,
  submitRedeemVoucherLogic,
  getServiceWithIdLogic,
  getServiceTransactionsLogic,
  getTypesLogic,
  getServiceProviderWithIdLogic,
  createServiceLogic,
  createChildServiceLogic,
  createServiceTypeLogic,
  updateServiceLogic,
  updateChildServiceLogic,
  updateServiceTypeLogic,
  deleteServiceLogic,
  getServiceOptionWithLogic,
  createServiceOptionLogic,
  createServiceProviderLogic,
  updateServiceOptionLogic,
  updateServiceProviderLogic,
  deleteServiceOptionLogic,
  deleteServiceProviderLogic,
  deleteServiceTypeLogic,
  getServiceTypesLogic,
  createVouchersLogic,
  getAvailableServicesLogic,
  redeemLogic
];
