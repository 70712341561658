import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import layoutReducer from "./layout/reducer";
import navbarReducer from "./navbar/reducer";
// -------------------
import { authReducer } from "./auth/reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "layout",
    "tenant",
    "store",
    "report",
    "terms",
    "transaction",
    "scibWalletTransactions",
    "city",
    "slap",
    "question",
    "suggestion",
    "attribute",
    "category",
    "product",
    "userManegment",
    "companyInfos",
    "dashboard",
    "code",
    "services",
    "settings",
    "notification",
    "advertisement",
    "client"
  ],
};

export default function createReducer(asyncReducers, history) {
  return persistReducer(
    rootPersistConfig,
    combineReducers({
      ...asyncReducers,
      router: connectRouter(history),
      app: require("./app/reducer").reducer,
      auth: authReducer,
      layout: layoutReducer,
      navbar: navbarReducer,
      userManegment: require("./userManagement/reducer").reducer,
      settings: require("./setting/reducer").reducer,
      companyInfos: require("./companyInfo/reducer").reducer,
      dashboard: require("./dashboard/reducer").reducer,
      tenant: require("./tenant/reducer").reducer,
      store: require("./store/reducer").reducer,
      category: require("./category/reducer").reducer,
      product: require("./product/reducer").reducer,
      attribute: require("./attribute/reducer").reducer,
      code: require("./code/reducer").reducer,
      city: require("./city/reducer").reducer,
      question: require("./question/reducer").reducer,
      services: require("./service/reducer").reducer,
      suggestion: require("./suggestion/reducer").reducer,
      transaction: require("./transaction/reducer").reducer,
      productSkus: require("./productSku/reducer").reducer,
      notification: require("./notification/reducer").reducer,
      slap: require("./slap/reducer").reducer,
      advertisement: require("./advertisement/reducer").reducer,
      terms: require("./terms/reducer").reducer,
      scibWalletTransactions: require("./scibWallet/reducer").reducer,
      report: require("./report/reducer").reducer,
      client:require("./client/reducer").reducer,
      request:require("./request/reducer").reducer,

    })
  );
}
