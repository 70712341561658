import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("tenants", { metadata: undefined }, []),
  ...defaultState("tenant", { metadata: undefined }, {}),
};

const createTenantContactSuccess = (state, draft, payload) => {
  draft.tenant = {
    ...state.tenant,
    contacts: [...state.tenant.contacts, payload],
  };
};
const updateTenantContactSuccess = (state, draft, payload) => {
  let contacts = [...state.tenant.contacts];
  let index = contacts.findIndex((e) => e.id == payload.id);
  contacts[index] = payload;
  draft.tenant = {
    ...state.tenant,
    contacts,
  };
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.user_errors = [];
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllTenants",
    stateName: "tenants",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTenantWithId",
    stateName: "tenant",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createTenant",
    stateName: "tenant",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateTenant",
    stateName: "tenant",
    async: true,
    isPaginated: false,
    // successCb: updateTenantSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteTenant",
    stateName: "tenant",
    async: true,
    isPaginated: false,
  }),

  //
  ...bindReducers(ActionTypes, {
    action: "createTenantConfiguration",
    stateName: "configuration",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "createTenantContact",
    stateName: "contact",
    async: true,
    isPaginated: false,
    successCb: createTenantContactSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateTenantConfiguration",
    stateName: "configuration",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "updateTenantContact",
    stateName: "contact",
    async: true,
    isPaginated: false,
    successCb: updateTenantContactSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getMyTenantInfo",
    stateName: "getMyTenantInfo",
    async: true,
    isPaginated: false,
    
  }),

  //
  ...bindReducers(ActionTypes, {
    action: "setTenant",
    stateName: "tenant",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: true,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  }),
});
