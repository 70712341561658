import apisauce from "apisauce";
import config from "../configs";

import authApi from "./auth";
import usersApi from "./userManagement";
import settingsApi from "./setting";
import tenantApi from "./tenant";
import storeApi from "./store";
import categoryApi from "./category";
import productApi from "./product";
import attributetApi from "./attribute";
import codeApi from "./code";
import cityApi from "./city";
import questionApi from "./question";
import companyInfoApi from "./companyInfo";
import servicesApi from "./service";
import suggestionApi from "./suggestion";
import transactionApi from "./transaction";
import slapApi from "./slap";
import notificationApi from "./notification";
import advertisementApi from "./advertisement";
import scibWalletTransactionsApi from "./scibWallet";
import termsApi from "./terms";
import productSkuApi from "./productSku";
import reportApi from "./reports";
import clientApi from "./client";
import requestApi from "./request";

import { store  as reduxStore} from "../store/index"


//---------------------

// const apiURI = config.useLocalApi ? config.devApiRoot : config.apiRoot;
const apiURI = process.env.REACT_APP_BASE_API_URL;
const create = (baseURL = apiURI) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 1200000, //30 sec
  });
  //add access token on each request

  api.addAsyncResponseTransform((response) => async () => {
    if (!response.ok) {
      const customeError = {
        errors: response.data.errors || [
          { errorMessage: response.data.message },
        ],
        errorMessage: response.data.message,
        status: response.status,
        code: response.data.errorCode,
      };
      response.data = customeError;
    }
  });

  api.addAsyncRequestTransform((request) => async () => {
    const token = localStorage.getItem("jwtToken");
    const culture = localStorage.getItem("culture");
    const cultureCode = localStorage.getItem("cultureCode");
    const userIp = localStorage.getItem("userIp");

    request.headers["culture"] = culture || "en";
    request.headers["cultureCode"] = cultureCode || "en-US";
    request.headers["userIp"] = userIp || "";
    const country=JSON.parse(localStorage.getItem('selectedCountry'));
    //reduxStore.getState()?.client?.savedCountry?.tenantID;
    if(country) request.headers["tenantId"] =country?.tenantID;

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete request.headers["Authorization"];
    }
  });

  const auth = authApi(api);
  const users = usersApi(api);
  const settings = settingsApi(api);
  const tenant = tenantApi(api);
  const store = storeApi(api);
  const category = categoryApi(api);
  const product = productApi(api);
  const attribute = attributetApi(api);
  const code = codeApi(api);
  const city = cityApi(api);
  const question = questionApi(api);
  const companyInfo = companyInfoApi(api);
  const services = servicesApi(api);
  const suggestion = suggestionApi(api);
  const transaction = transactionApi(api);
  const notification = notificationApi(api);
  const slap = slapApi(api);
  const advertisements = advertisementApi(api);
  const terms = termsApi(api);
  const productSku = productSkuApi(api);
  const scibWalletTransactions = scibWalletTransactionsApi(api);
  const report= reportApi(api);
  const client = clientApi(api);
  const request = requestApi(api);



  // --------------------
  return {
    setHeader: api.setHeader,
    setHeaders: api.setHeaders,
    ...auth,
    ...users,
    ...settings,
    ...tenant,
    ...store,
    ...category,
    ...product,
    ...attribute,
    ...code,
    ...city,
    ...question,
    ...services,
    ...companyInfo,
    ...suggestion,
    ...transaction,
    ...notification,
    ...slap,
    ...productSku,
    ...advertisements,
    ...terms,
    ...scibWalletTransactions,
    ...report,
    ...client,
    ...request
  };
};

export default { create };
