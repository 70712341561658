import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useSignalR } from '../hooks/useSignalR';
import notificationsActions from "src/store/notification/actions";
import tenantActions from 'src/store/tenant/actions'



export const SignalProvider = React.createContext();

function SignalRProvider({ children, ...props }) {

    const { startHub, onReceiveNotify ,closeHub} = useSignalR();
  const dispatch = useDispatch();
  const {getNotifications, readNotification,setNotifications} = notificationsActions;
  const { notifications } = useSelector((state) => state.auth);

    const {
        user
    } = useSelector(state => state.auth)
    
    React.useEffect(() => {
        if (user) {
            dispatch(tenantActions.getMyTenantInfo())
            const token = localStorage.getItem("jwtToken");
            startHub(token);
            // onReceiveNotify();
            return;
        }
        
        //closeHub();
    }, [user])
    React.useEffect(()=>{
       //  dispatch(getNotifications({page: 1, pageSize: 1000 }))
  },[])

    return (
        <SignalProvider.Provider
            value={{ ...props, startHub: startHub, onReceiveNotify: onReceiveNotify }}
        >
            {children}
        </SignalProvider.Provider>
    )
}

export default SignalRProvider
