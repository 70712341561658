export default function (/**@type {ApisauceInstance} */ api) {
  const getAllTenants = (paging) => api.get("/tenant/search", paging);

  const getTenantWithId = (id) => api.get("/tenant/find/" + id);

  const createTenant = (payload) => api.post("/tenant/create", payload);

  const updateTenant = (payload) =>
    api.put("/tenant/edit/" + payload.id, payload);

  const deleteTenant = (id) => api.delete("/tenant/" + id);

  //extra
  const createTenantConfiguration = (payload) =>
    api.post("/tenant/configuration", payload);

  const updateTenantConfiguration = (payload) =>
    api.put("/tenant/edit-configuration", payload);

  const createTenantContact = (payload) => api.post("/tenant/contact", payload);

  const updateTenantContact = (payload) =>
    api.put("/tenant/edit-contact", payload);
  const getMyTenantInfo = () =>api.get("/tenant/my-tenant-info");

  return {
    tenant: {
      getAllTenants,
      getTenantWithId,
      createTenant,
      updateTenant,
      deleteTenant,
      //
      createTenantConfiguration,
      createTenantContact,
      updateTenantConfiguration,
      updateTenantContact,
      getMyTenantInfo
    },
  };
}
