
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";


const apiNamespace = "Client";

const getAllClientsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CLIENTS,
});
const getClientWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CLIENT_WITH_ID,
});
const getClientInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CLIENT_INFO,
});
const createClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CLIENT,
});
const updateClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_CLIENT,
});
const deleteClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CLIENT,
});
const registerLogic = logic(apiNamespace, {
  actionName: ActionTypes.REGISTER,
  showErrorMessage: true,
  errorMessage: <Trans id="invalid_registeration"/>,
  showSuccessMessage: false,
  // successMessage:"Welcome back",
  successCb: (dispatch, payload) => {
    dispatch(push("/verify-otp"));
  },
});

export default [
  getAllClientsLogic,
  getClientWithIdLogic,
  createClientLogic, 
  updateClientLogic,
  deleteClientLogic,
  getClientInfoLogic,
  registerLogic
];
  
  