import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllTenants: { args: ["payload"], meta: { async: true } },
    getTenantWithId: { args: ["payload"], meta: { async: true } },
    createTenant: { args: ["payload"], meta: { async: true } },
    updateTenant: { args: ["payload"], meta: { async: true } },
    deleteTenant: { args: ["payload"], meta: { async: true } },
    //
    createTenantConfiguration: { args: ["payload"], meta: { async: true } },
    createTenantContact: { args: ["payload"], meta: { async: true } },
    updateTenantConfiguration: { args: ["payload"], meta: { async: true } },
    updateTenantContact: { args: ["payload"], meta: { async: true } },
    getMyTenantInfo: { args: ["payload"], meta: { async: true } },

    //
    setTenant: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/tenant/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
