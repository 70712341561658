

export default function (/**@type {ApisauceInstance} */ api) {

  const getTerms = (tenantId) =>
    api.get("/terms/search?TenantId="+JSON.parse(localStorage.getItem("selectedCountry"))?.tenantID );
  const updateTerms = (payload) => api.put("/terms/edit", payload);
  const createTerm = (payload) => api.post("/terms/create", payload);

  const getTerm = (id) => api.get("/terms/find/" + id);

  const deleteTerms = (id) => api.delete("/terms/remove/" + id);

  return {
    terms: {
      getTerms,
      updateTerms,
      deleteTerms,
      createTerm,
      getTerm,
    },
  };
}
