
  
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("clients", { metadata: undefined }, []),
  ...defaultState("client", { metadata: undefined }, {}),
};
const saveCountry=(state, draft, payload)=>{

localStorage.setItem("selectedCountry",JSON.stringify(payload))

}
const registerSuccess= (state, draft, payload) => {
 if(state.savedPhone)  localStorage.setItem("userPhone",state.savedPhone)
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const setRegister=(state, draft, payload) => {
  draft.registerRes_errors = undefined;

};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.user_errors = [];
};
export const reducer = createReducer(initialState, {         
  ...bindReducers(ActionTypes, {
     action: "getAllClients",
     stateName: "clients",
     async: true,
     isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
    action: "getClientInfo",
    stateName: "clientInfo",
    async: true,
    isPaginated: false
 }),
  ...bindReducers(ActionTypes, {
     action: "getClientWithId",
     stateName: "client",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "createClient",
    stateName: "client",
    async: true,
    isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
     action: "updateClient",
     stateName: "client",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteClient",
    stateName: "client",
    async: true,
    isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "register",
    stateName: "client",
    async: true,
    isPaginated: false,
    successCb:registerSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "setRegister",
    stateName: "setRegisterRes",
    async: false,
    isPaginated: false,
    successCb: setRegister,
  }),
  ...bindReducers(ActionTypes, {
    action: "saveCountry",
    stateName: "savedCountry",
    async: false,
    isPaginated: false,
    successCb: saveCountry,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "saveUserPhone",
    stateName: "savedPhone",
    async: false,
    isPaginated: false,
  }),
});
  